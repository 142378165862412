import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/pjarema/next-14-spl/apps/next-spl/src/components/html/ArticleSection.js");
;
import(/* webpackMode: "eager" */ "/home/pjarema/next-14-spl/apps/next-spl/src/components/layout/Container.module.css");
;
import(/* webpackMode: "eager" */ "/home/pjarema/next-14-spl/apps/next-spl/src/components/layout/ColumnsCss.module.css");
;
import(/* webpackMode: "eager" */ "/home/pjarema/next-14-spl/apps/next-spl/src/components/shared/Footer.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/pjarema/next-14-spl/apps/next-spl/src/components/shared/Navbar.js");
;
import(/* webpackMode: "eager" */ "/home/pjarema/next-14-spl/apps/next-spl/styles/Main.module.css");
;
import(/* webpackMode: "eager" */ "/home/pjarema/next-14-spl/node_modules/next/dist/client/link.js");
